(function($) {
    $('.index-banner').slick({
        fade: true,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: false,
        dots: false,
        arrows: true,
        appendArrows: $('.index-banner-control'),
        prevArrow: '<button class="slick-prev bt" aria-label="Previous" type="button"><i class="ic ic-arrow-left"></i> PREV</button>',
        nextArrow: '<button class="slick-next bt" aria-label="Next" type="button">NEXT <i class="ic ic-arrow-right"></i></button>',
    }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.index-banner-page .current').text((nextSlide+1).toString().padStart(2, '0'));
    }).on('setPosition', function(event, slick){
        $('.index-banner-page .total').text(slick.$slides.length.toString().padStart(2, '0'));
    });
    function resizedw(){
        $('.index-footer').css('width', $(window).height());
    }
    var doit;
    $(window).on('resize', function(event) {
        clearTimeout(doit);
        doit = setTimeout(resizedw, 100);
    }).resize();
    $(window).on('load', function () {
        resizedw();
    });
})($);